import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="bg-gray-50 dark:bg-gray-900 p-4 sm:ml-56">
      <footer className="text-gray-800 dark:text-gray-200 py-8 px-4">
        <div className="container mx-auto flex flex-wrap justify-between items-center">
          {/* Left Section: Logo and Description */}
          <div className="w-full lg:w-1/3 mb-6 lg:mb-0 text-center lg:text-left">
            <h2 className="text-2xl font-semibold flex gap-2 items-center">
              <img src="/logo.png" alt="TheGameZone Logo" className="w-[30px] h-[30px]" />
              GameZone
            </h2>
            <p className="mt-2 text-gray-600 dark:text-gray-400">
              Play the best online games for free. Enjoy a wide variety of games on desktop, mobile, and tablet.
            </p>
          </div>

          {/* Center Section: Navigation Links */}
          <div className="w-full lg:w-1/3 mb-6 lg:mb-0 text-center">
            <nav className="space-x-4">
              <Link to="/about" className="hover:text-gray-400 dark:hover:text-gray-300 transition-colors">
                About Us
              </Link>
              <Link to="/contact" className="hover:text-gray-400 dark:hover:text-gray-300 transition-colors">
                Contact
              </Link>
              <Link to="/privacy-policy" className="hover:text-gray-400 dark:hover:text-gray-300 transition-colors">
                Privacy Policy
              </Link>
              <Link to="/terms" className="hover:text-gray-400 dark:hover:text-gray-300 transition-colors">
                Terms of Service
              </Link>
              <Link to="/DMCA" className="hover:text-gray-400 dark:hover:text-gray-300 transition-colors">
                DMCA
              </Link>
            </nav>
          </div>

          {/* Right Section: Social Media Links */}
          <div className="w-full lg:w-1/3 flex justify-center lg:justify-end space-x-4">
            <a href="https://www.facebook.com/yourprofile" target="_blank" aria-label="Facebook">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M22 12h-4v8H14v-8h-2V8h2V6c0-2.2 1.8-4 4-4h2v4h-2c-1.1 0-2 .9-2 2v2h4l-1 4h-3v8h-4v-8H9v-4h4V6c0-4 2.5-6 6-6s6 2.5 6 6v4h4v4z" />
              </svg>
            </a>


            <a href="https://twitter.com/yourhandle" target="_blank" aria-label="Twitter">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M22 4.01c-.77.34-1.59.57-2.46.68.89-.53 1.57-1.36 1.89-2.36-.83.5-1.75.86-2.72 1.04a4.03 4.03 0 0 0-6.85 3.66c-3.34-.17-6.3-1.76-8.29-4.17-.35.59-.55 1.28-.55 2 0 1.38.7 2.58 1.74 3.28-.64-.02-1.26-.2-1.8-.5v.05c0 1.94 1.38 3.57 3.21 3.95-.34.09-.69.14-1.04.14-.26 0-.52-.02-.78-.07.52 1.63 2.03 2.82 3.81 2.85a8.08 8.08 0 0 1-5.01 1.73c-.33 0-.66-.02-.98-.06 1.84 1.17 4.02 1.85 6.35 1.85 7.62 0 11.78-6.3 11.78-11.78 0-.18-.01-.35-.03-.52.81-.58 1.5-1.31 2.05-2.14z" />
              </svg>
            </a>


            <a href="https://www.instagram.com/yourusername" target="_blank" aria-label="Instagram">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M12 2.16c3.13 0 3.49.01 4.72.07 1.13.06 1.97.26 2.44.44a4.86 4.86 0 0 1 1.77 1.13 4.86 4.86 0 0 1 1.13 1.77c.18.47.38 1.31.44 2.44.06 1.23.07 1.59.07 4.72s-.01 3.49-.07 4.72c-.06 1.13-.26 1.97-.44 2.44a4.86 4.86 0 0 1-1.13 1.77 4.86 4.86 0 0 1-1.77 1.13c-.47.18-1.31.38-2.44.44-1.23.06-1.59.07-4.72.07s-3.49-.01-4.72-.07c-1.13-.06-1.97-.26-2.44-.44a4.86 4.86 0 0 1-1.77-1.13 4.86 4.86 0 0 1-1.13-1.77c-.18-.47-.38-1.31-.44-2.44-.06-1.23-.07-1.59-.07-4.72s.01-3.49.07-4.72c.06-1.13.26-1.97.44-2.44a4.86 4.86 0 0 1 1.13-1.77 4.86 4.86 0 0 1 1.77-1.13c.47-.18 1.31-.38 2.44-.44 1.23-.06 1.59-.07 4.72-.07zm0 5.64c-1.75 0-3.16 1.42-3.16 3.16s1.42 3.16 3.16 3.16 3.16-1.42 3.16-3.16-1.42-3.16-3.16-3.16zm0 5.02c-1.04 0-1.87-.83-1.87-1.87s.83-1.87 1.87-1.87 1.87.83 1.87 1.87-.83 1.87-1.87 1.87zm5.26-4.64c-.69 0-1.25-.56-1.25-1.25s.56-1.25 1.25-1.25 1.25.56 1.25 1.25-.56 1.25-1.25 1.25z" />
              </svg>
            </a>



            <a href="https://www.youtube.com/channel/yourchannel" target="_blank" aria-label="YouTube">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <path d="M23 7s-1 3-1 3v6s0 3-1 3H2s-1 0-1-3V10s0-3 1-3h21s1 0 1 3v4zM9 14V9l5 2.5L9 14z" />
              </svg>
            </a>



          </div>
        </div>

        {/* Bottom Section: Copyright */}
        <div className="mt-8 border-t border-gray-300 dark:border-gray-700 pt-4 text-center text-gray-600 dark:text-gray-500">
          <p>&copy; 2024 TheGameZone.fun, All Rights Reserved.</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
