import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import GameCard from '../../components/GameCard/GameCard';

function GamesPreview() {
    const { name } = useParams();
    const [state, setState] = useState([]);
    const [gamesList, setGamesList] = useState([]);

    const getGameListFunc = async () => {
        const api_res = await axios.get("https://pub.gamezop.com/v3/games?id=9632&lang=en");
        setGamesList(api_res?.data?.games)
        const New_Data = api_res?.data?.games?.filter(
            (item) => item?.name?.en?.toLowerCase().replace(/\s+/g, "-") === name
        );
        setState(New_Data[0] || null);
    };

    useEffect(() => {
        getGameListFunc();
    }, [name]);

    useEffect(() => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    }, [state]);


    useEffect(() => {
        try {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        } catch (e) {
            console.error("Adsbygoogle failed to load:", e);
        }
    }, [state]);

    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8383910619750608";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);

        // Push the ad settings after the script is loaded
        script.onload = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error("AdSense error:", e);
            }
        };

        // Cleanup on unmount
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div className=' p-4 sm:ml-56'>
            <Helmet>
                {/* Meta Tags */}
                <title>{`Play ${state?.name?.en} Online | TheGameZone – Free Game Preview`}</title>
                <meta name="description" content={`${state?.description?.en} | TheGameZone`} />
                <meta name="keywords" content={`play ${state?.name?.en} online, free ${state?.name?.en} game, ${state?.name?.en} game preview, online games, TheGameZone`} />
                <meta property="og:title" content={`Play ${state?.name?.en} Online | TheGameZone – Free Game Preview`} />
                <meta property="og:description" content={`Discover ${state?.name?.en} on TheGameZone! ${state?.description?.en}`} />
                <meta property="og:url" content={`https://thegamezone.fun/game/${state?.slug}`} />
                <meta property="og:image" content={`https://thegamezone.fun/assets/images/games/${state?.image}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:image" content={`https://thegamezone.fun/assets/images/games/${state?.image}`} />
                <link rel="canonical" href={`https://thegamezone.fun/game/${state?.slug}`} />
            </Helmet>

            <div className="relative flex flex-col lg:flex-row mt-14">
                <div className="flex flex-wrap lg:flex-nowrap gap-4">
                    {/* Game Preview Section */}
                    <div className="flex-1">
                        <div className="w-full lg:w-[1000px]">
                            <div className="h-[500px] sm:h-full 2xl:h-[700px]">
                                <div className="h-full bg-black rounded-xl relative">
                                    <div className="h-full opacity-60">
                                        <img src={state?.assets?.square} className="w-full h-full rounded-xl" alt={state?.name?.en} />
                                    </div>
                                    <div className="w-full h-full text-white flex flex-col justify-center items-center absolute top-0 left-0 z-[2]">
                                        <div className="text-center">
                                            <div className="h-64 mb-4 hidden md:block">
                                                <img
                                                    src={state?.assets?.square}
                                                    className="w-full h-full shadow-2xl border-4 border-white rounded-full"
                                                    alt={state?.name?.en}
                                                />
                                            </div>
                                            <div className="my-6 font-bold text-3xl">{state?.name?.en}</div>
                                            <div
                                                className="w-[200px] bg-[#5AC4D1] rounded-full flex cursor-pointer mx-auto justify-center gap-2 py-4"
                                                onClick={() => window.open(state?.url)}
                                            >
                                                <b className="text-[#ffff] text-xl">PLAY GAME</b>
                                            </div>

                                            <div className="text-center mt-5 ">
                                                <ins class="adsbygoogle"
                                                    style={{ display: "inline-block", width: "300px", height: "100px" }}
                                                    data-ad-client="ca-pub-8383910619750608"
                                                    data-ad-slot="6473234616"
                                                // data-ad-format="auto"
                                                // data-full-width-responsive="true"
                                                ></ins>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Right Side Info Section */}
                    <div className="lg:w-[300px] w-full lg:ml-4 dark:bg-gray-800 bg-gray-50 p-6 rounded-xl text-black dark:text-white">
                        <h2 className="text-xl font-bold mb-4">Game Details</h2>

                        {state?.name?.en && (
                            <p>
                                <b>Name:</b> {state?.name?.en}
                            </p>
                        )}

                        {state?.description?.en && (
                            <p>
                                <b>Description:</b> {state?.description?.en}
                            </p>
                        )}

                        {state?.categories?.en?.length > 0 && (
                            <p>
                                <b>Categories:</b> {state?.categories?.en?.join(", ")}
                            </p>
                        )}

                        {state?.tags?.en?.length > 0 && (
                            <div className="mb-4">
                                <b>Tags:</b>
                                <div className="flex flex-wrap gap-2 mt-2">
                                    {state?.tags?.en?.map((tag, index) => (
                                        <span
                                            key={index}
                                            className="bg-blue-500 text-white px-3 py-1 text-sm rounded-full"
                                        >
                                            {tag}
                                        </span>
                                    ))}
                                </div>
                            </div>
                        )}

                        {state?.url && (
                            <p>
                                <b>URL:</b>{" "}
                                <a
                                    href={state?.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="text-blue-400 underline break-words"
                                >
                                    {state?.url}
                                </a>
                            </p>
                        )}

                        {state?.isPortrait !== undefined && (
                            <p>
                                <b>Is Portrait:</b> {state?.isPortrait ? "Yes" : "No"}
                            </p>
                        )}

                        <div className="text-center mt-5 ">
                            <ins class="adsbygoogle"
                                style={{ display: "inline-block", width: "300", height: "200px" }}
                                data-ad-client="ca-pub-8383910619750608"
                                data-ad-slot="6473234616"
                            // data-ad-format="auto"
                            // data-full-width-responsive="true"
                            ></ins>
                        </div>
                    </div>

                    <div className=''>
                        <ins class="adsbygoogle"
                            style={{ display: 'block', textAlign: 'center' }}
                            data-ad-layout="in-article"
                            data-ad-format="fluid"
                            data-ad-client="ca-pub-8383910619750608"
                            data-ad-slot="1567601337"></ins>
                    </div>
                </div>
            </div>

            {/* Bottom Horizontal Ad */}
            <div className="w-full mt-5">
                <ins class="adsbygoogle"
                    style={{ display: "inline-block" }}
                    data-ad-client="ca-pub-8383910619750608"
                    data-ad-slot="2124511243"></ins>
            </div>


            <div className='mt-5'>
                <h1 className='text-black dark:text-white font-bold text-[24px]'>New Games</h1>
                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-4 mt-2">
                    {gamesList && gamesList?.slice(30, 80)?.map((_ele, i) => {
                        const GetSlug = _ele?.name?.en?.toLowerCase().replace(/\s+/g, "-");
                        return (<>
                            <GameCard key={i} id={i} slug={GetSlug} name={_ele?.name?.en} banner={_ele?.assets?.square} link={_ele?.URL} />
                        </>
                        )
                    }

                    )}
                    {gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                        <h2 className='text-[28px] font-bold'>
                            No games found.
                        </h2>
                    </div>
                    }
                </div>
            </div>

        </div>
    );
}

export default GamesPreview;
