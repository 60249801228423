// src/App.js
import React, { useEffect } from 'react';
import { useDarkMode } from '../../contexts/DarkModeContext';
import GameCard from '../../components/GameCard/GameCard'
import { Helmet } from 'react-helmet';

const Home = () => {
    const { gamesList } = useDarkMode();


    useEffect(() => {
        // Dynamically load the AdSense script
        const script = document.createElement("script");
        script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-8383910619750608";
        script.async = true;
        script.crossOrigin = "anonymous";
        document.body.appendChild(script);

        script.onload = () => {
            try {
                (window.adsbygoogle = window.adsbygoogle || []).push({});
            } catch (e) {
                console.error("AdSense error:", e);
            }
        };

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    useEffect(() => {
        try {
            window.adsbygoogle = window.adsbygoogle || [];
            window.adsbygoogle.push({});
        } catch (e) {
            console.error("Adsbygoogle failed to load:", e);
        }
    }, []);

    return (
        <div>
            <Helmet>
                <meta charset="utf-8" />
                <meta name="apple-mobile-web-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="black-translucent" />
                <meta name="apple-mobile-web-app-title" content="TheGameZone.fun" />
                <meta name="HandheldFriendly" content="true" />
                <meta name="mobile-web-app-capable" content="yes" />
                <meta http-equiv="X-UA-Compatible" content="IE=edge" />
                <meta name="google-adsense-account" content="ca-pub-8383910619750608" />
                <link rel="icon" href="%PUBLIC_URL%/favicon.ico" />
                <link rel="apple-touch-icon" href="%PUBLIC_URL%/logo.png" />
                <link rel="manifest" href="%PUBLIC_URL%/manifest.json" />
                <meta name="viewport"
                    content="width=device-width, initial-scale=1.0, minimal-ui, shrink-to-fit=no, viewport-fit=cover" />

                <title>Play Free Online Games | TheGameZone – Best Games for All Ages</title>
                <meta name="title" content="Play Free Online Games | TheGameZone – Best Games for All Ages" />
                <meta name="description"
                    content="Discover a world of free online games at TheGameZone! Play top-rated games across action, puzzle, and multiplayer genres. Fun for all ages—start playing now!" />

                <meta name="keywords" content="free online games, play games online, TheGameZone, best free games, online gaming" />

                <meta property="og:title" content="Play Free Online Games | TheGameZone – Best Games for All Ages" />
                <meta property="og:description"
                    content="Discover a world of free online games at TheGameZone! Play top-rated games across action, puzzle, and multiplayer genres. Fun for all ages—start playing now!" />
                <meta property="og:url" content="https://thegamezone.fun/" />
                <meta property="og:site_name" content="TheGameZone.fun" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://thegamezone.fun/logo.png" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Play Free Online Games | TheGameZone – Best Games for All Ages" />
                <meta name="twitter:description"
                    content="Discover a world of free online games at TheGameZone! Play top-rated games across action, puzzle, and multiplayer genres. Fun for all ages—start playing now!" />
                <meta name="twitter:image" content="https://thegamezone.fun/logo.png" />

                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://thegamezone.fun/" />
            </Helmet>

            <div className="p-4 mt-14 sm:ml-56">
                <div className='flex justify-center'>
                    {/* <!-- home-top --> */}
                    <ins
                        className="adsbygoogle"
                        style={{ display: "inline-block" }}
                        data-ad-client="ca-pub-8383910619750608"
                        data-ad-slot="2574950302"
                        data-ad-format="auto"
                        data-full-width-responsive="true"
                    ></ins>
                </div>

                <h1 className='text-black dark:text-white font-bold text-[24px]'>👍 You may like</h1>
                <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-4 mt-2">
                    {gamesList && gamesList?.slice(0, 50)?.map((_ele, i) => {
                        const GetSlug = _ele?.name?.en?.toLowerCase().replace(/\s+/g, "-");
                        return (<>
                            <GameCard key={i} id={i} slug={GetSlug} name={_ele?.name?.en} banner={_ele?.assets?.square} link={_ele?.URL} />
                        </>
                        )
                    }

                    )}
                    {gamesList.length === 0 && <div className="col-span-12 lg:col-span-12 rounded-lg bg-white w-full h-40 flex justify-center items-center my-4">
                        <h2 className='text-[28px] font-bold'>
                            No games found.
                        </h2>
                    </div>
                    }
                </div>
            </div>
        </div>
    );
};

export default Home;
