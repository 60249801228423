import React from 'react'
import {
  BrowserRouter,
  Route,
  Routes
} from 'react-router-dom';
import Layout from './components/Layout/Layout';
import GamesPreview from './pages/Games/GamesPreview';
import Category from './pages/Category/Category';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import PrivacyPolicy from './pages/PrivacyPolicy/PrivacyPolicy';
import Terms from './pages/Terms/Terms';
import Dmca from './pages/Dmca/Dmca';

function App() {
  return (
    <div className='bg-white dark:bg-gray-900'>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route path="/" element={<Home />} />
            <Route path="/category/:name" element={<Category />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/DMCA" element={<Dmca />} />
            <Route path="/game/:name" element={<GamesPreview />} />
          </Route>
          {/* <Route path="/game/:name" element={<GamesPreview />} /> */}
        </Routes>
      </BrowserRouter>

    </div >
  )
}

export default App